<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_iabm.course_wise_lesson_assign') }}
              <slot v-if="$route.query.id">
                {{ $t('elearning_config.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="lesson-assign-circular-wise" :class="'btn btn-success text-light'">{{ $t('elearning_iabm.course_wise_lesson_assign') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loader">
                      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="fiscal_year_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                      plain
                                      v-model="data.fiscal_year_id"
                                      :options="fiscalYearList"
                                      id="fiscal_year_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select> -->
                                  <v-select
                                      id="fiscal_year_id"
                                      v-model="data.fiscal_year_id"
                                      :reduce="op => op.value"
                                      :options="fiscalYearList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1">
                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                              >
                                <template v-slot:label>
                                  {{ $t('externalUserIrrigation.organization')}} <span class="text-danger">*</span>
                                </template>
                                <!-- <b-form-select
                                    plain
                                    v-model="data.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select> -->
                                <v-select
                                      id="org_id"
                                      v-model="data.org_id"
                                      :reduce="op => op.value"
                                      :options="orgList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          </b-row>
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="training_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="data.training_type_id"
                                    :options="trainingTypeList"
                                    id="training_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_type_id"
                                      v-model="data.training_type_id"
                                      :reduce="op => op.value"
                                      :options="trainingTypeList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_category_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="data.training_category_id"
                                    :options="trainingCategoryList"
                                    id="training_category_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_category_id"
                                      v-model="data.training_category_id"
                                      :reduce="op => op.value"
                                      :options="trainingCategoryList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_title_id"
                                  slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="data.training_title_id"
                                    :options="trainingTitleList"
                                    id="training_title_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="training_title_id"
                                      v-model="data.training_title_id"
                                      :reduce="op => op.value"
                                      :options="trainingTitleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="circular_memo_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="data.circular_memo_no"
                                      :options="circularList"
                                      id="circular_memo_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Batch No" vid="batch_no" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="batch_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_iabm.batch_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="data.batch_no"
                                      :options="batchList"
                                      id="batch_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Course Module"  vid="course_module_id">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="course_module_id"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.course_module')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.course_module_id"
                                    :options="courseModuleList"
                                    id="course_module_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                      <div id="form" >
                        <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.entry')}}</legend>
                      <div class="p-2">
                        <ValidationObserver ref="form1" v-slot="{ handleSubmit, reset }">
                        <b-form @submit.prevent="handleSubmit(addItem)" @reset.prevent="reset" autocomplete="off">
                          <!-- <b-row class="p-4">
                            <b-col style="background-color: var(--gray-eee);" lg="6" class="offset-lg-3 p-2">
                              <template>
                                <h6 style="font-weight: bold;" class="text-sm-center">{{ $t('elearning_tim.expertise_trainer') }} {{ $t('globalTrans.details') }}</h6>
                              </template>
                            </b-col>
                          </b-row> -->
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Course Module"  vid="course_module_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="course_module_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.course_module')}} <span class="text-danger">*</span>
                                  </template>
                                  <!-- <b-form-select
                                    plain
                                    v-model="detailsFormData.course_module_id"
                                    :options="courseModuleList"
                                    id="course_module_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select> -->
                                    <v-select
                                      id="course_module_id"
                                      v-model="detailsFormData.course_module_id"
                                      :reduce="op => op.value"
                                      :options="courseModuleList"
                                      :placeholder="$t('globalTrans.select')"
                                      :filter-by="myFilter"
                                      label="text"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    </v-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Course"  vid="course_management_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="course_management_id"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_tim.lesson')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="detailsFormData.course_management_id"
                                    :options="courseList"
                                    id="course_management_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <b-col lg="12" sm="12">
                              <ValidationProvider name="Lesson"  vid="course_management_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="course_management_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_tim.lesson')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-checkbox-group
                                      id="course_management_id"
                                      v-model="detailsFormData.course_management_id"
                                      :options="courseList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <!-- <b-row>
                                      <b-col
                                          v-for="course in courseList"
                                          :key="course.id"
                                          cols="auto"
                                      >
                                          <b-form-checkbox :value="course.id">
                                              {{ course.text }}
                                          </b-form-checkbox>
                                      </b-col>
                                  </b-row> -->
                                  </b-form-checkbox-group>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col>
                                <div class="text-right mb-2">
                                    <b-button type="button" v-if="editIndex !== ''" variant="danger" class="mr-1 btn-sm" @click="cancelItem()">{{ $t('globalTrans.cancel') }}</b-button>
                                    <b-button type="submit" class="btn-sm" variant="success">{{ editIndex !== '' ? $t('elearning_config.update') : $t('globalTrans.add')}}</b-button>
                                </div>
                            </b-col>
                          </b-row>
                        </b-form>
                      </ValidationObserver>
                      </div>
                    </fieldset>
                      </div>
                      <br/>
                    <!-- List  -->
                    <b-row id="form">
                  <div class="col-md-12">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.lesson') + ' ' + $t('globalTrans.details')}}</legend>
                      <table class="table" style="width:100%;padding: 5px">
                        <thead class="thead">
                          <tr>
                            <th style="width:6%">{{ $t('globalTrans.sl_no') }}</th>
                            <th style="width:20%">{{$t('elearning_tpm.course_module')}} </th>
                            <th style="width:20%">{{$t('elearning_tim.lesson')}} </th>
                            <th style="width:8%">{{ $t('globalTrans.action') }}</th>
                          </tr>
                        </thead>
                        <tr v-for="(item, index) in data.details" :key="index">
                          <b-td>
                            {{ $n(index + 1) }}
                          </b-td>
                          <b-td>
                            {{ getCourseModuleName(item.course_module_id) }}
                          </b-td>
                          <b-td>
                            <span v-for="(itm, indx) in item.course_management_id" :key="indx">
                                <span>{{ $n(indx + 1) }}{{' . '}}{{ getCourseName(itm) }}</span>
                                <span v-if="indx + 1 < item.course_management_id.length">{{ ', ' }}<br/></span>
                            </span>
                          </b-td>
                          <b-td>
                              <span class="d-flex text-center">
                                <b-button @click="editItem(item, index)" title="Location Edit" class="btn btn-success btn-sm mr-1"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                <b-button type="button" @click="deleteItem(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                              </span>
                            </b-td>
                        </tr>
                        <b-tr v-if="data.details.length <= 0">
                              <td colspan="8" class="text-center">
                                  <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                              </td>
                        </b-tr>
                      </table>
                    </fieldset>
                  </div>
                </b-row>
                    <b-row class="text-right">
                      <b-col>
                        <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                        <!-- <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button> -->
                        <!-- <b-button type="button" @click="finalSave()" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.finalSave') }}</b-button> -->
                        <!-- <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button> -->
                        <router-link to="lesson-assign-circular-wise" class="mr-1 btn btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                      </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
          </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { lessonAssignCourseWiseShow, circularList, lessonAssignCourseWiseUpdate, lessonAssignCourseWiseStore, courseWiseModule, personalInfoDetails } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.data.org_id = this.$store.state.Auth.authUser.org_id
    }
    this.data.fiscal_year_id = this.$store.state.TrainingElearning.currentFiscalYearId
    if (this.$route.query.id) {
      this.getExpertiseTrainerData(this.$route.query.id)
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      circularLoading: false,
      valid: null,
      // loading: false,
      loader: false,
      loadingCourseModule: false,
      saveBtnName: this.$route.query.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        fiscal_year_id: 0,
        circular_memo_no: 0,
        org_id: '',
        training_category_id: null,
        training_type_id: null,
        training_title_id: null,
        batch_no: 0,
        details: []
      },
      detailsFormData: {
        course_module_id: null,
        course_management_id: []
      },
      editIndex: '',
      officeTypeList: [],
      trainers: [],
      userList: [],
      officeList: [],
      courseModuleList: [],
      courseList: [],
      trainerListData: [],
      trainingCategoryList: [],
      course_module_errors: [],
      circularList: [],
      batchList: [],
      allBatchListData: [],
      circularErrorMsg: false,
      courseError: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: [],
      trainingTypeList: [],
      orgType: [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ],
      myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
    }
  },
  computed: {
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    },
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    orgTypeList: function () {
      return this.orgType.map((obj, key) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
    },
    jobTypeList () {
      return this.$store.state.TrainingElearning.commonObj.jobTypeList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    currentFiscalYearId: function () {
      return this.$store.state.TrainingElearning.currentFiscalYearId
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'data.org_id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.data.id) {
          this.data.training_type_id = ''
        }
        this.trainingTypeList = this.getTypeList(newValue)
      }
    },
    'data.training_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.data.id) {
          this.data.training_category_id = ''
        }
        this.trainingCategoryList = this.getCategoryList(newVal)
      } else {
        this.trainingCategoryList = []
      }
    },
    'data.training_category_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.data.id) {
          this.data.training_title_id = ''
        }
        this.trainingTitleList = this.getTrainingTitleList(newVal)
      } else {
        this.trainingTitleList = []
      }
    },
    'data.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.data.id) {
          this.data.circular_memo_no = 0
          this.data.batch_no = 0
          this.detailsFormData.course_module_id = null
        }
        this.getCircularList()
        this.courseModuleList = this.getCourseModuleList(newVal)
      } else {
        this.courseModuleList = []
      }
    },
    'data.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.data.id) {
          this.data.circular_memo_no = 0
          this.data.batch_no = 0
        }
          this.getCircularList()
      }
    },
    'detailsFormData.course_module_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.data.id) {
          this.detailsFormData.course_management_id = []
        }
          this.courseList = this.getCourseList(newVal)
      } else {
        this.courseList = []
      }
    },
     'data.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.data.batch_no = allBatchListData.batch_no
        }
      }
    },
    'data.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.data.circular_memo_no = allBatchListData.circular_memo_no
        }
      }
    }
  },
  methods: {
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === orgId)
      }
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.data.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.data.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.data.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      if (this.data.fiscal_year_id && this.data.training_title_id) {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.data.fiscal_year_id,
          training_type_id: this.data.training_type_id,
          training_category_id: this.data.training_category_id,
          training_title_id: this.data.training_title_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
          this.allBatchListData = []
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      }
    },
    getTrainingTypeName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingTitleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getTrainingCatName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseModuleName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseModuleList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getCourseName (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.courseList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    cancelItem () {
        this.detailsFormData = {}
        this.editIndex = ''
        this.$refs.form1.reset()
    },
    editItem (item, index) {
        this.detailsFormData = Object.assign({}, item)
        this.editIndex = index
    },
    deleteItem (index) {
        this.data.details.splice(index, 1)
    },
    async addItem () {
      let result = []
      if (this.editIndex !== '') {
          const editList = [...this.data.details]
          editList.splice(parseInt(this.editIndex), 1)
          result = [...editList]
      } else {
          result = this.data.details
      }

      const courseModuleEn = this.detailsFormData.course_module_id
      const newData = result.find(item => item.course_module_id === courseModuleEn)
      if (typeof newData === 'undefined') {
          if (this.editIndex !== '') {
              // this.data.details[parseInt(this.editIndex)] = this.detailsFormData
              this.data.details[parseInt(this.editIndex)].course_module_id = this.detailsFormData.course_module_id
              this.data.details[parseInt(this.editIndex)].course_management_id = this.detailsFormData.course_management_id
          } else {
              this.data.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
          }
          this.detailsFormData = {
            course_module_id: '',
            course_management_id: []
          }
          this.editIndex = ''
      } else {
          this.$toast.error({
              title: '!',
              message: 'Item already added',
              color: '#C0EC83'
          })
      }
      this.$nextTick(() => {
          this.$refs.form1.reset()
      })
    },
    async saveUpdate () {
      var check = await this.$refs.form.validate()
      if (check) {
        if (!this.data.details.length) {
          return this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: 'Add at least one item!',
            color: 'red'
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        this.loader = true
        // this.data.flag = this.flag_status
        const loadingState = { loading: false, listReload: false }
        if (this.data.id) {
          result = await RestApi.putData(trainingElearningServiceBaseUrl, `${lessonAssignCourseWiseUpdate}/${this.data.id}`, this.data)
        } else {
          result = await RestApi.postData(trainingElearningServiceBaseUrl, lessonAssignCourseWiseStore, this.data)
        }
        this.loader = false
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.data.id ? this.$t('elearning_config.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$router.push('/training-e-learning-service/iabm/lesson-assign-circular-wise')
          // this.$bvModal.hide('modal-form')
          } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    },
    async courseWiseModule () {
      this.loadingCourseModule = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseWiseModule + '/' + this.formData.course_module_id)
      if (!result.success) {
        this.courseModuleListReset()
      } else {
        if (result.data.length > 0) {
          this.formData.lessons = result.data
        } else {
          this.courseModuleListReset()
        }
        this.formData.lessons_remove = []
      }
      this.loadingCourseModule = false
    },
    courseModuleListReset () {
      this.formData.lessons = [
        {
          id: '',
          course_name: null,
          course_name_bn: null,
          course_details: null,
          course_details_bn: null
        }
      ]
      this.formData.lessons_remove = []
    },
    getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
    },
    getCourseList (typeId) {
       const courseList = this.$store.state.TrainingElearning.commonObj.courseList.filter(item => item.status === 1)
       if (typeId) {
         return courseList.filter(item => item.course_module_id === typeId)
       }
    },
    getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    getCourseModuleList (trainingTitleId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList.filter(item => item.status === 1)
      if (trainingTitleId) {
        return courseModuleList.filter(item => item.training_title_id === trainingTitleId)
      }
    },
    getUser () {
        const serchData = {
        }
        RestApi.getData(trainingElearningServiceBaseUrl, personalInfoDetails, serchData).then(response => {
            if (response.success) {
                this.userList = response.data.map((obj, index) => {
                    if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                    } else {
                    return { value: obj.id, text: obj.name }
                    }
                })
            } else {
                this.userList = []
            }
        })
    },
    async getExpertiseTrainerData (id) {
      this.loader = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, lessonAssignCourseWiseShow + '/' + id)
      if (result.success) {
        this.data = result.data
        this.data.details = result.details
        this.loader = false
      } else {
        this.loader = false
      }
    }
  }
}
</script>
